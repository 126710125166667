export default {
  name: 'transportation-accounts-drivers',
  props: {
    signedInUser: Object,
    hasUserType: Function,
    hasTransportationRole: Function,
    forceRootViewRefresh: Function,
    selectedAccount: Object
  },
  mounted () {
    // console.log('this.$props.signedInUser', this.$props.signedInUser)
  },
  computed: {
    transportationOwnerId () {
      return this.$props.selectedAccount ? this.$props.selectedAccount.id : (this.$route.params.accountId === '0' ? null : parseInt(this.$route.params.accountId))
    }
  }
}
